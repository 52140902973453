import * as React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Account, AccountFeatures, User, UserRole } from '@citrite/sf-api';
import { BillingType } from '@sharefile/ecomm-models/dist/orders';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { ApplicationContextProvider } from './ApplicationContextProvider';
import ConvertToPaid from './ConvertToPaid/ConvertToPaid';
import { AsyncCancelAccount, AsyncEditBillingInfo, AsyncManagePlan } from './EditBilling';
import { FeatureFlag } from './featureFlags';
import { getLeftNavBarText, RegisteredLeftNavItems } from './leftNavTranslations';
import { showManagePlan } from './managePlanCheck';
import { AsyncReceiptsPage } from './Receipts';
import {
	AdminBillingRoute,
	CancelRoute,
	ConvertFreeTrialRoute,
	EditBillingInfoRoute,
	ManagePlanRoute,
	ReceiptsRoute,
} from './routes';

export function setup(piletApi: PiletApi) {
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const account = context.account;
	const user = context.user;
	const accountId = context.account.Id;
	const userId = context.user.Id;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const isFINRA = context.account.Preferences?.IsFINRA ?? false;
	const isVDR = (context.account.PlanTrack ?? '') === 'VDR';

	if (accountId && userId && showManagePlan(context)) {
		piletApi.registerPage(ManagePlanRoute, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncManagePlan
					accountId={accountId}
					userId={userId}
					isFreeTrial={isFreeTrial}
					account={account}
					datePreferences={context.user.Preferences?.DateFormat}
					timePreferences={context.user.Preferences?.TimeFormat}
					isVDR={isVDR}
					isFINRA={isFINRA}
				/>
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ManagePlanRoute,
			title: () => getLeftNavBarText(RegisteredLeftNavItems.ManagePlan),
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (
		getFeatureFlagValue(FeatureFlag.CancelPageUsePilet, false) &&
		showCancelAccount(user, account)
	) {
		piletApi.registerPage(CancelRoute, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncCancelAccount account={account}></AsyncCancelAccount>
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: CancelRoute,
			title: () => getLeftNavBarText(RegisteredLeftNavItems.Cancel),
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (showConvertToPaid(user, account)) {
		piletApi.registerPage(`${ConvertFreeTrialRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<ConvertToPaid />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ConvertFreeTrialRoute,
			title: () => getLeftNavBarText(RegisteredLeftNavItems.ConvertFreeTrial),
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (
		accountId &&
		userId &&
		getFeatureFlagValue(FeatureFlag.EditBillingInfoUsePilet, false) &&
		showBillingInfo(account, userId)
	) {
		piletApi.registerPage(`${EditBillingInfoRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncEditBillingInfo />
			</ApplicationContextProvider>
		));

		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: EditBillingInfoRoute,
			title: () => getLeftNavBarText(RegisteredLeftNavItems.EditBillingInfo),
			weight: 200,
			parent: AdminBillingRoute,
		});
	}

	if (
		getFeatureFlagValue(FeatureFlag.ReceiptsPageUsePilet, false) &&
		showReceipts(account, user)
	) {
		piletApi.sf.registerLeftSecondaryNavChildComponent({
			href: ReceiptsRoute,
			title: () => getLeftNavBarText(RegisteredLeftNavItems.Receipts),
			weight: 200,
			parent: AdminBillingRoute,
		});
		piletApi.registerPage(`${ReceiptsRoute}/:orderId?/:successFlag?`, () => (
			<ApplicationContextProvider piletApi={piletApi}>
				<AsyncReceiptsPage />
			</ApplicationContextProvider>
		));
	}

	function showBillingInfo(acc: Account, uId: string) {
		if (!isSFHybrisAccount(context.account.BillingType)) {
			return false;
		}

		return (
			acc.Preferences?.ShowBillingInfo ||
			(acc.Preferences?.HideBillingInfoExceptions || '').indexOf(uId) !== -1
		);
	}

	function getDisableAdminBillingSectionPreference(acc: Account) {
		return acc.Preferences?.DisableAdminBillingSectionInApp || '';
	}

	function showConvertLink(acc: Account) {
		return acc.IsFreeTrial && acc.ConversionType === 'manual';
	}

	function showConvertToPaid(u: User, acc: Account) {
		if (!acc.CanConvertFreeTrial) {
			return false;
		}

		if (!showConvertLink(acc)) {
			return false;
		}

		if (!u.Roles?.includes(UserRole.AdminBilling)) {
			return false;
		}

		if (!showBillingInfo(acc, u.Id!)) {
			return false;
		}

		if (acc.BillingType !== 'Hybris' && acc.BillingType !== 'SFHybris') {
			return false;
		}

		return true;
	}

	function showReceipts(acc: Account, u: User) {
		if (!isSFHybrisAccount(context.account.BillingType)) {
			return false;
		}

		if (!u.Roles?.includes(UserRole.AdminViewReceipts)) {
			return false;
		}

		if (acc.IsFreeTrial) {
			return false;
		}

		if (!showBillingInfo(acc, u.Id!)) {
			return false;
		}

		if (acc.AccountFeatures?.includes(AccountFeatures.IsPlanTrackFreemium)) {
			return false;
		}

		if (acc.IsCTXSBilledSMBAccount) {
			return false;
		}

		if (getDisableAdminBillingSectionPreference(acc) === 'DisableAll') {
			return false;
		}

		return true;
	}
}

export function showCancelAccount(u: User, acc: Account) {
	if (!u.Roles?.includes(UserRole.AdminAccountPolicies)) {
		return false;
	}
	if (!u.Roles?.includes(UserRole.AdminChangePlan)) {
		return false;
	}
	if (acc.IsCancelled ?? false) {
		return false;
	}
	if (acc.BillingType !== BillingType.SFHybris) {
		return false;
	}
	if (!(acc.CanCancel ?? true)) {
		return false;
	}
	if (acc.Preferences?.DisableAdminBillingSectionInApp === 'ShowReceipts') {
		return false;
	}
	if (acc.Preferences?.DisableAdminBillingSectionInApp === 'DisableAll') {
		return false;
	}

	return true;
}

function isSFHybrisAccount(billingType?: string) {
	return billingType === 'SFHybris';
}

// remove once stage deploy succeeds
