import React from 'react';
import { Button, Flex, Modal, Typography } from 'antd';
import { t } from '../../../../translate';

const { Text, Title } = Typography;

export type ModalProps = {
	price: string;
	onClose: () => void;
	handleConvert: () => void;
	isProcessing: boolean;
};

export const ConvertModal = (props: ModalProps) => {
	const modalTitle = () => {
		return (
			<Flex align="center" data-testid="convert-modal">
				<Title style={{ margin: '0' }} level={2}>
					{t('convert_to_paid.convert_modal.confirm_purchase')}
				</Title>
			</Flex>
		);
	};

	return (
		<Modal
			open
			centered
			footer={null}
			title={modalTitle()}
			closeIcon={null}
			destroyOnClose
		>
			<Flex vertical gap={12}>
				<Text>
					{t('convert_to_paid.convert_modal.confirm_purchase_description', {
						billingRate: props.price,
					})}
				</Text>
				<Flex>
					<Button
						onClick={props.handleConvert}
						type="primary"
						loading={props.isProcessing}
						data-testid="modal-convert-now-button"
					>
						{t('convert_to_paid.buttons.convert_now')}
					</Button>
					<Button
						onClick={props.onClose}
						disabled={props.isProcessing}
						data-testid="modal-cancel-button"
					>
						{t('convert_to_paid.buttons.cancel')}
					</Button>
				</Flex>
			</Flex>
		</Modal>
	);
};
