import { PaymentGatewayHPPRequest } from '@sharefile/ecomm-models/dist/apis/ecomm/paymentGateway';
import { ProductCodes } from '@sharefile/ecomm-models/dist/plans';

export const SortedSfPlans = [
	ProductCodes.INDUSTRY_ADVANTAGE,
	ProductCodes.PREMIUM,
	ProductCodes.ADVANCED,
	ProductCodes.ADVANCED0GB,
	ProductCodes.STANDARD,
	ProductCodes.STANDARD0GB,
];

export enum Referrer {
	TrialConversion = 'WebappTrialConversion',
	EditBillingInfo = 'WebappEditBillingInfo',
	UpdateBillingType = 'WebappUpdateBillingType',
	OneTimePayment = 'WebappOneTimePayment',
	AcceptPlanChangeQuote = 'WebappAcceptPlanChangeQuote',
}

export interface AccountCancellationRequest {
	password: string;
	reason?: string;
	reasonCode?: number;
}

export type WorldPayRedirect = {
	redirectUrl: string;
};

export type SuccessFlag = 'success' | 'failure' | 'cancel';

export type PaymentGatewayFPIRequest = PaymentGatewayHPPRequest & {
	cancelUrl: string;
};
