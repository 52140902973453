import { Account, CreditCardAuthorizationReferrer } from '@citrite/sf-api/dist/Models';
import {
	BillingAccountRequest,
	CreditCardInformationRequest,
	CurrencyCode,
	EcommerceBillingAccountResponse,
} from '@sharefile/ecomm-models/dist/apis/ecomm';
import {
	BillingCycle,
	OrderAction,
	SalesforceProductCode,
} from '@sharefile/ecomm-models/dist/orders';
import {
	PlanBase,
	PlanCatalog,
	PlanKey,
	ProductKey,
} from '@sharefile/ecomm-models/dist/plans';
import { BillingInfo, User } from '@sharefiledev/sharefile-appshell';
import { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { PurchaseOrderInformation } from '../../types';
import apiClient from '../apiClient';
import { ECOMM_API_PATH } from '../common';
import { PaymentGatewayFPIRequest, SuccessFlag, WorldPayRedirect } from './models';

export const getAccountBillingInfo = async (
	accountId: string,
	skipPaymentInfo: boolean
): Promise<EcommerceBillingAccountResponse | undefined> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			`/billing/account?accountId=${accountId}&skipPaymentInfo=${skipPaymentInfo}`
		);

		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get billing info, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const getCurrentPlan = async (accountId: string): Promise<PlanBase> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			`/plans/current?accountId=${accountId}`
		);

		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get current plan, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const getPlanCatalog = async (): Promise<PlanCatalog[]> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			'/plans/catalog?productCode=SFPREM001&productCode=SFADV001&productCode=SFIA001&productCode=SFVDR001'
		);
		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get plan catalog, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

//#region Worldpay Payment Gateway

export const getSuccessUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'success', pathParam);
};

export const getFailureUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'failure', pathParam);
};

export const getCancelUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'cancel', pathParam);
};

const getUrl = (orderCode: string, successFlag: SuccessFlag, pathParam?: string) => {
	if (pathParam) {
		return `${
			window.location.origin
		}${pathParam}/${orderCode}/${successFlag}?orderId=${orderCode}&status=${successFlag.toUpperCase()}&`;
	} else {
		return `${window.location.href}/${orderCode}/failure?orderId=${orderCode}&status=FAILURE`;
	}
};

export const generateOrderCode = () => `${uuid()}`;

export const redirectToWorldpayPaymentGateway = async (
	currencyCode: CurrencyCode,
	referrer: CreditCardAuthorizationReferrer,
	account: Account,
	user: User,
	route: string
) => {
	try {
		const orderDescription = 'Trial conversion for account ' + account.Id;
		const orderCode = referrer.OrderCode!;

		const successUrl = getSuccessUrl(orderCode, route);
		const failureUrl = getFailureUrl(orderCode, route);
		const cancelUrl = getCancelUrl(orderCode, route);

		const referrerAddress = JSON.parse(referrer.ReferrerState!).Address;

		const request: PaymentGatewayFPIRequest = {
			orderCode,
			orderDescription,
			shopper: {
				firstName: user.FirstName!,
				lastName: user.LastName!,
				emailAddress: user.Email!,
				companyName: account.CompanyName,
			},
			billingAddress: {
				address1: referrerAddress.StreetAddress1,
				address2: referrerAddress.StreetAddress2,
				city: referrerAddress.City,
				state: referrerAddress.StateOrProvince,
				zip: referrerAddress.PostalCode,
				country: referrerAddress.Country,
			},
			successUrl,
			failureUrl,
			cancelUrl,
			currency: currencyCode,
		};

		const redirectResponse: AxiosResponse<WorldPayRedirect> = await apiClient(
			ECOMM_API_PATH
		).post<WorldPayRedirect>('/payment/gateway/fullpageintegration', request);

		window.location.href = redirectResponse.data.redirectUrl;
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to process payment, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

//#endregion

//#region OrdersPurchase lambda

export const createOrdersPurchaseRequest = (
	orderCode: string,
	account: Account,
	billingInfo: BillingInfo,
	user: User,
	totalUsers: number,
	billingCycle: BillingCycle,
	planKey?: PlanKey,
	code?: SalesforceProductCode,
	accountId?: string
): PurchaseOrderInformation => {
	const request: PurchaseOrderInformation = {
		orderCode,
		orderPlan: {
			shopper: {
				firstName: user.FirstName!,
				lastName: user.LastName!,
				emailAddress: user.Email!,
				companyName: account.CompanyName!,
			},
			billingAddress: {
				address1: billingInfo.Address1!,
				address2: billingInfo.Address2!,
				city: billingInfo.City!,
				state: billingInfo.State!,
				zip: billingInfo.Zip!,
				country: billingInfo.Country!,
			},
			billingCycle,
			products: [
				{
					productKey: ProductKey.ShareFile,
					planKey,
					salesforceProductCode: code,
					quantity: totalUsers,
					instanceId: accountId,
				},
			],
			orderAction: OrderAction.TrialConversion,
		},
	};

	return request;
};

export const ecommOrdersPurchase = async (request: PurchaseOrderInformation) => {
	try {
		return await apiClient(ECOMM_API_PATH).post('/orders/purchase', request);
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to process payment, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

//#endregion

//#region Edit Billing Info
export const updateAccountBillingInfo = async (
	billingAccountRequest: BillingAccountRequest
) => {
	try {
		return await apiClient(ECOMM_API_PATH).patch(
			'/billing/account',
			billingAccountRequest
		);
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to update billing info, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const updateCreditCardInformation = async (
	creditCardInformationRequest: CreditCardInformationRequest
) => {
	try {
		return await apiClient(ECOMM_API_PATH).post(
			'creditcard/information',
			creditCardInformationRequest
		);
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to update credit card info, details: ${JSON.stringify(
				error
			)}`
		);
	}
};
//#endregion
