import { Address, CreditCardAuthorizationReferrer } from '@citrite/sf-api';
import {
	BillingAddressResponse,
	CurrencyCode,
} from '@sharefile/ecomm-models/dist/apis/ecomm/';
import { Account, User } from '@sharefiledev/sharefile-appshell';
import { getCancelUrl, getFailureUrl, getSuccessUrl } from './ecommClient/ecommApi';
import { PaymentGatewayFPIRequest, Referrer } from './ecommClient/models';

export const ECOMM_API_PATH = '/io/ecommerce';

export function getAddressModel(billingAddress: BillingAddressResponse) {
	const address: Address = {
		StreetAddress1: billingAddress.street,
		City: billingAddress.city,
		StateOrProvince: billingAddress.state,
		PostalCode: billingAddress.postalCode,
		Country: billingAddress.country,
	};
	return address;
}

export function getCreditCardAuthorizationReferrerModel(
	referrer: Referrer,
	accountId: string,
	referrerState?: string
) {
	const creditCardAuthorizationReferrer: CreditCardAuthorizationReferrer = {
		OrderCode: '',
		Referrer: referrer,
		AccountId: accountId,
		ReferrerState: referrerState,
	};
	return creditCardAuthorizationReferrer;
}

export const createFPIRequest = (
	referrer: CreditCardAuthorizationReferrer,
	account: Account,
	user: User,
	route: string,
	orderDescription: string,
	address?: Address
) => {
	if (!address) {
		address = JSON.parse(referrer.ReferrerState!).Address;
	}

	const request: PaymentGatewayFPIRequest = {
		orderCode: referrer.OrderCode!,
		orderDescription,
		shopper: {
			firstName: user.FirstName!,
			lastName: user.LastName!,
			emailAddress: user.Email!,
			companyName: account.CompanyName,
		},
		billingAddress: {
			address1: address?.StreetAddress1 ?? '',
			address2: address?.StreetAddress2 ?? '',
			city: address?.City ?? '',
			state: address?.StateOrProvince ?? '',
			zip: address?.PostalCode ?? '',
			country: address?.Country ?? '',
		},
		successUrl: getSuccessUrl(referrer.OrderCode!, route),
		failureUrl: getFailureUrl(referrer.OrderCode!, route),
		cancelUrl: getCancelUrl(referrer.OrderCode!, route),
		currency: CurrencyCode.USD,
	};
	return request;
};
