import React from 'react';
import {
	Account,
	BillingInfo,
	CreditCardAuthorizationReferrer,
	PaymentAuthorizationEntity,
} from '@citrite/sf-api';
import { CurrencyCode } from '@sharefile/ecomm-models/dist/apis/ecomm';
import { sizes } from '@sharefiledev/antd-config';
import { User } from '@sharefiledev/sharefile-appshell';
import { Alert, Button, Col, Flex, Row, theme, Typography } from 'antd';
import {
	generateOrderCode,
	redirectToWorldpayPaymentGateway,
} from '../../../api/ecommClient/ecommApi';
import { SuccessFlag } from '../../../api/ecommClient/models';
import { t } from '../../../translate';

const { Text } = Typography;

export interface Props {
	creditCardAuthorizationReferrer: CreditCardAuthorizationReferrer;
	isEditCreditCardInfoDisabled?: boolean;
	account: Account;
	user: User;
	successFlag?: SuccessFlag;
	route: string;
	billingInfo?: BillingInfo;
}

export const CreditCardInfoPaymentGateway = (props: Props) => {
	const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

	const { token } = theme.useToken();

	const getRedirectUrl = async () => {
		const referrerResponse =
			await PaymentAuthorizationEntity.createCreditCardAuthorizationReferrerAsync({
				...props.creditCardAuthorizationReferrer,
				OrderCode: generateOrderCode(),
			}).execute();

		await redirectToWorldpayPaymentGateway(
			CurrencyCode.USD,
			referrerResponse,
			props.account,
			props.user,
			props.route
		);
		setIsProcessing(false);
	};

	return (
		<Flex
			vertical
			justify="flex-start"
			gap={sizes.SM}
			style={{
				border: `1px solid ${token.colorBorder}`,
				borderRadius: token.borderRadius,
				padding: sizes.MD,
			}}
		>
			{props.billingInfo?.CreditCardToken && (
				<Row data-testid="credit-card-billing-info">
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>{t('edit_billing_information.name_on_card')}</Text>
							<Text>
								{props.billingInfo?.CreditCardFirstName}{' '}
								{props.billingInfo?.CreditCardLastName}
							</Text>
						</Flex>
					</Col>
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>{t('edit_billing_information.credit_card.card_number')}</Text>
							<Text>********{props.billingInfo?.CreditCardToken}</Text>
						</Flex>
					</Col>
					<Col span={8}>
						<Flex vertical gap={sizes.XS}>
							<Text strong>
								{t('edit_billing_information.credit_card.card_exp_date')}
							</Text>
							<Text>
								{props.billingInfo?.CreditCardExpirationMonth}/
								{props.billingInfo?.CreditCardExpirationYear}
							</Text>
						</Flex>
					</Col>
				</Row>
			)}
			<Flex>
				<Button
					type="primary"
					onClick={async () => {
						setIsProcessing(true);
						await getRedirectUrl();
					}}
					disabled={props.isEditCreditCardInfoDisabled}
					style={{ padding: `0 ${sizes.MD}` }}
					data-testid="credit-card-info-button"
					loading={isProcessing}
				>
					{t('convert_to_paid.credit_card.edit')}
				</Button>
			</Flex>
			{props.successFlag && props.successFlag !== 'cancel' && (
				<Alert
					message={
						props.successFlag === 'success'
							? t('convert_to_paid.credit_card.confirmed_description')
							: t('convert_to_paid.credit_card.error_message')
					}
					type={props.successFlag === 'success' ? 'success' : 'error'}
					showIcon
					data-testid="credit-card-alert"
				/>
			)}
		</Flex>
	);
};
